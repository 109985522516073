import React, { useState, useEffect, useRef } from 'react';
import dog from './img/dog.png';
import sound from './typing.mp3';
import backgroundMusicFile from './music.mp3'; // Import your background music file
import './App.css';
import img1 from "./img/img1.png";
import img2 from "./img/img2.png";
import img3 from "./img/img3.png";

const typingSound = new Audio(sound);

const Terminal = () => {
  // State variables for the terminal functionality
  const [output, setOutput] = useState([]);
  const [input, setInput] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [hasInteracted, setHasInteracted] = useState(false);
  const inputRef = useRef(null);
  const terminalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [hasShownMenu, setHasShownMenu] = useState(false);

  const backgroundMusic = useRef(new Audio(backgroundMusicFile));

  // State variables for the fetched data
  const [tokenName, setTokenName] = useState('');
  const [telegramLink, setTelegramLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [tokenCA, setTokenCA] = useState('');
  const [pumpLink, setPumpLink] = useState('');

  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch('https://apitoreturnca.onrender.com/api/purchaseData')
      .then(response => response.json())
      .then(data => {
        // Assuming the API returns data in the following format:
        // {
        //   tokenName: "$DOG",
        //   twitterLink: "https://x.com/digdew",
        //   telegramLink: "https://t.me/digdew",
        //   walletAddress: "3bik67pHeG2T8ckotutTjdjyMMPBtZ7EzsqkTz1RvyJp",
        //   tokenCA: "DFyRs5AvWBG4EZRvemczEYKs8e29NNdTGPaSJvXQpump",
        //   link: "https://pump.fun/DFyRs5AvWBG4EZRvemczEYKs8e29NNdTGPaSJvXQpump"
        // }
        setTokenName(data.tokenName);
        setTelegramLink(data.telegramLink);
        setTwitterLink(data.twitterLink);
        setWalletAddress(data.walletAddress);
        setTokenCA(data.tokenCA);
        setPumpLink(data.link);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    // Ensure the input is always focused
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isTyping, showMenu]); // Refocus input when typing is done or menu is shown

  useEffect(() => {
    // Scroll to bottom when output updates
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [output]);

  useEffect(() => {
    // Add keydown listener for interaction
    const handleKeyPress = () => {
      if (!hasInteracted) {
        handleInitialInteraction();
      }
    };

    window.addEventListener('keydown', handleKeyPress); // Listen to any key press

    return () => {
      window.removeEventListener('keydown', handleKeyPress); // Clean up after interaction
    };
  }, [hasInteracted]);

  const typeMessage = (message, callback, addToOutput = true) => {
    setIsTyping(true);
    let index = 0;
    let tempMessage = '';

    // Start typing sound
    typingSound.play().catch((error) => console.log('Audio play prevented:', error));

    const typingInterval = setInterval(() => {
      if (index < message.length) {
        tempMessage += message[index];
        setCurrentMessage(tempMessage); // Update the message being typed
        index++;
      } else {
        clearInterval(typingInterval);
        typingSound.pause();
        typingSound.currentTime = 0;
        setIsTyping(false);
        setCurrentMessage(''); // Clear current message

        if (addToOutput) {
          setOutput((prev) => [...prev, tempMessage]); // Add the complete message to output
        }

        if (callback) callback();
      }
    }, 100);
  };

  const handleCommand = (e) => {
    if (e.key === 'Enter' && !isTyping) {
      const choice = input.trim().toLowerCase(); // Trim and convert to lowercase
      let response = '';

      if (choice === 'menu') {
        setShowMenu(true);
        setInput('');
        return;
      }

      // Keep the menu commands valid even when the menu is not displayed
      switch (choice) {
        case '1':
          // Hide menu after selection
          setShowMenu(false);
          response = `${tokenCA} - Go to pump or copy`;

          // Add the animated message
          setOutput((prev) => [...prev, `> ${input}`]); // Show the entered command
          typeMessage(response, () => {
            // After typing animation, remove the animated message and render the "pump" link and "copy" button
            setOutput((prev) => {
              const newOutput = [...prev];
              // Remove the last message (the one that was animated)
              newOutput.pop();
              // Add the complete message with clickable link and copy button
              newOutput.push(
                <div key={prev.length}>
                  {tokenCA} - Go to{' '}
                  <a href={pumpLink} target="_blank" rel="noopener noreferrer">
                    pump
                  </a>{' '}
                  or{' '}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(tokenCA);
                      // Instead of an alert, use typing animation for success message
                      typeMessage('Token copied to clipboard!');
                    }}
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    copy
                  </a>
                </div>
              );
              return newOutput;
            });
          });
          break;
        case '2':
          // Hide menu after selection
          setShowMenu(false);
          response = 'Here are the images:';

          setOutput((prev) => [...prev, `> ${input}`]); // Show the entered command
          typeMessage(response, () => {
            // After typing animation, display images
            setOutput((prev) => [
              ...prev,
              <div key={prev.length} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={img1} alt="Image 1" style={{ width: '150px', marginLeft: '0' }} />
                <img src={img2} alt="Image 2" style={{ width: '150px', marginLeft: '-400px' }} />
                <img src={img3} alt="Image 3" style={{ width: '150px', marginLeft: '-400px' }} />
              </div>,
            ]);
          });
          break;
        case '3':
          // Hide menu after selection
          setShowMenu(false);
          response = '1 billion , 0% taxes, 100% community';
          setOutput((prev) => [...prev, `> ${input}`]);
          typeMessage(response);
          break;
        case '4':
          // Hide menu after selection
          setShowMenu(false);
          response = 'Community is being built on telegram';
          setOutput((prev) => [...prev, `> ${input}`]);
          typeMessage(response);
          break;
        case '5':
          // Hide menu after selection
          setShowMenu(false);
          response = `${tokenName} is a fun, community-driven memecoin, born in code and inspired by the love for an adorable puppy. Join us`;
          setOutput((prev) => [...prev, `> ${input}`]);
          typeMessage(response);
          break;
        case '6':
          // Hide menu after selection
          setShowMenu(false);
          // Toggle music playback
          if (isMusicPlaying) {
            backgroundMusic.current.pause();
            setIsMusicPlaying(false);
            response = 'Music paused.';
          } else {
            backgroundMusic.current.play();
            setIsMusicPlaying(true);
            response = 'Music playing.';
          }
          setOutput((prev) => [...prev, `> ${input}`]);
          typeMessage(response);
          break;
        default:
          response = 'Invalid command, please choose a number from the menu.';
          setOutput((prev) => [...prev, `> ${input}`]);
          typeMessage(response);
      }

      setInput(''); // Reset input after processing the command
    }
  };

  const handleInitialInteraction = () => {
    if (!hasInteracted) {
      setHasInteracted(true);
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false); // End loading animation
        setShowImage(true); // Show image after interaction
        // Start background music
        backgroundMusic.current.play();
        setIsMusicPlaying(true);
        // Type welcome message
        typeMessage(`Hello, I'm ${tokenName}, here is my social media:`, () => {
          // Type "Telegram - " without adding to output
          typeMessage('Telegram - ', () => {
            // Add Telegram link to output
            setOutput((prev) => [
              ...prev,
              <div key={`telegram-${prev.length}`}>
                <a href={telegramLink} target="_blank" rel="noopener noreferrer">
                  Telegram
                </a>
              </div>,
            ]);

            // Type "Twitter/X - " without adding to output
            typeMessage('Twitter/X - ', () => {
              // Add Twitter/X link to output
              setOutput((prev) => [
                ...prev,
                <div key={`twitter-${prev.length}`}>
                  <a href={twitterLink} target="_blank" rel="noopener noreferrer">
                    Twitter/X
                  </a>
                </div>,
              ]);

              // Type "PumpFun - " without adding to output
              typeMessage('PumpFun - ', () => {
                // Add PumpFun link to output
                setOutput((prev) => [
                  ...prev,
                  <div key={`pumpfun-${prev.length}`}>
                    <a href={pumpLink} target="_blank" rel="noopener noreferrer">
                      PumpFun
                    </a>
                  </div>,
                ]);

                // Show menu after all messages have been typed
                setShowMenu(true);
                setHasShownMenu(true); // Indicate that the menu has been shown
              }, false);
            }, false);
          }, false);
        });
      }, 2000);
    }
  };

  const handleInputBlur = () => {
    // Wait a small interval to allow the next element to receive focus
    setTimeout(() => {
      const activeElement = document.activeElement;
      if (activeElement.tagName !== 'A' && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  return (
    <div className="cover">
      <div
        className="crt"
        style={{
          color: 'rgba(255,255,255, 0.2)',
          fontFamily: 'monospace',
          fontSize: '18px',
          padding: '0',
          margin: '0',
          position: 'relative',
        }}
        onClick={handleInitialInteraction} // Ensure interaction starts typing and sound
      >
        {!hasInteracted && (
          <div className="click">
            <p>Click or press any key to start</p>
          </div>
        )}

        {isLoading && (
          <div className="loading-animation">
            <div className="loader"></div>
          </div>
        )}

        {showImage && (
          <div>
            <img className="dog" src={dog} alt="TerminalDog" />
          </div>
        )}

        <div ref={terminalRef} id="terminal" className="terminal">
          {output.map((line, index) => (
            <div key={index}>{line}</div>
          ))}

          {/* Display the message currently being typed */}
          {isTyping && <div>{currentMessage}</div>}

          {/* Show content when not typing */}
          {!isTyping && (
            <div>
              {showMenu ? (
                // Show the menu
                <div className="text">
                  <p>1. contract</p>
                  <p>2. memes</p>
                  <p>3. tokenomics</p>
                  <p>4. community</p>
                  <p>5. about me</p>
                  <p>6. {isMusicPlaying ? 'pause music' : 'play music'}</p>
                </div>
              ) : (
                hasShownMenu && (
                  <div className="text">
                    <p>Type "menu" to show the menu</p>
                  </div>
                )
              )}
              {/* Input field */}
              <div className="env-inp">
                <span> &gt; </span>
                <input
                  ref={inputRef} // Attach the ref to the input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)} // Update input state
                  onKeyDown={handleCommand} // Handle input commands directly on input
                  onBlur={handleInputBlur} // Automatically refocus the input on blur
                  autoFocus
                  disabled={!hasInteracted || isTyping} // Disable input while typing or until user has interacted
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Terminal;